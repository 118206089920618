<section class="admin edit-admin">
  <div class="container p-5">
    <div class="row">
      <div class="col-12 mb-5">
        <app-logo
          [imageUrl]="logoUrl"
          [url]="'/' + adminUrl + '/' + adminWhiteLabelUrl"></app-logo>
      </div>

      <div class="col-12 col-md-12">
        <h1>
          <span class="text-aluminium">Editar /</span>

          {{ name }}
        </h1>
      </div>

      <div class="col-12 mt-4">
        <p-tabView>
          <p-tabPanel header="Aparência">
            <ng-template pTemplate="content">
              <app-adm-branding
                (nameChanged)="nameChanged($event)"
                (tableDisabledChange)="
                  isTableDisabledChange($event)
                "></app-adm-branding>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel header="Banner" [disabled]="isTableDisabled">
            <ng-template pTemplate="content">
              <app-adm-banner></app-adm-banner>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel header="Menu" [disabled]="isTableDisabled">
            <ng-template pTemplate="content">
              <app-adm-menu></app-adm-menu>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel header="Rodapé" [disabled]="isTableDisabled">
            <ng-template pTemplate="content">
              <app-adm-footer></app-adm-footer>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel header="Conteúdo" [disabled]="isTableDisabled">
            <ng-template pTemplate="content">
              <div class="row">
                <div class="col-12">
                  <app-adm-content></app-adm-content>
                </div>
              </div>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel header="FAQ" [disabled]="isTableDisabled">
            <ng-template pTemplate="content">
              <div class="row">
                <div class="col-12 col-lg-6 mx-auto">
                  <app-adm-faq></app-adm-faq>
                </div>
              </div>
            </ng-template>
          </p-tabPanel>

          <p-tabPanel header="Redes Sociais" [disabled]="isTableDisabled">
            <ng-template pTemplate="content">
              <div class="row">
                <div class="col-12 col-lg-6 mx-auto">
                  <app-adm-social-media></app-adm-social-media>
                </div>
              </div>
            </ng-template>
          </p-tabPanel>

        <p-tabPanel header="Relatórios" [disabled]="isTableDisabled">
          <ng-template pTemplate="content">
            <div class="row">
                <app-adm-report></app-adm-report>
              </div>
          </ng-template>
        </p-tabPanel>

      </p-tabView>

      </div>
    </div>
  </div>
</section>
