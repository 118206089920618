import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TokenService } from '@core/services/auth/token.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { RefreshPageService } from '@core/services/refresh-page.service';
import { AuthFlowService } from '@modules/auth/services/auth-flow/auth-flow.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { TitleComponent } from '@modules/auth/shared/title/title.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';
import { TimerService } from '@shared/services/timer/timer.service';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-thank-you',
  standalone: true,
  imports: [CommonModule, TitleComponent, ButtonComponent, ParagraphComponent],
  templateUrl: './thank-you.component.html',
})
export class ThankYouComponent implements OnInit, AfterViewInit {
  timeLeft$!: Observable<number>;
  resendTimer$!: Observable<string>;

  private destroy$ = new Subject<void>();

  constructor(
    public timerService: TimerService,
    private sidebarDrawerService: SidebarDrawerService,
    private refreshPageService: RefreshPageService,
    private navigationService: NavigationService,
    private authFlowService: AuthFlowService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.initialTimer();

    this.tokenService.saveToken(this.userToken, this.userName);
  }

  ngAfterViewInit(): void {
    this.resendTimer$
      .pipe(takeUntil(this.destroy$))
      .subscribe(time =>  {
        if(time === ''){
          this.destroy$.next();
          this.destroy$.complete();
          this.sidebarDrawerService.close();
          this.navigationService.navigateTo('/veiculos')
        }
      });
  }

  initialTimer(): void {
    this.timeLeft$ = this.timerService.timeLeft$;
    this.timerService.startTimer(5);
    this.resendTimer$ = this.timerService.resendTimer$;

  }

  navigationVehicles(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.sidebarDrawerService.close();
    this.navigationService.navigateTo('/veiculos');
  }

  get userName(): string {
    return this.authFlowService.getUserName;
  }

  get userToken(): string {
    return this.authFlowService.getUserToken;
  }
}
