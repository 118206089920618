import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { VehicleFilterService } from '@core/services/vehicle-filter.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ButtonsPaginatorComponent } from '@shared/components/paginator/components/buttons/buttons.component';
import { PaginatorCustom } from '@shared/components/paginator/models/paginator.model';

@Component({
  selector: 'app-paginator',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    ParagraphComponent,
    ButtonsPaginatorComponent,
  ],
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss',
})
export class PaginatorComponent implements OnInit {
  @Input() totalItems: number = 0;
  @Input() pageSizeOptions = [12, 24, 36];

  @Output() pageChange = new EventEmitter<PaginatorCustom>();

  currentPage = 1;
  pageSize = 12;

  constructor(private vehicleFilterService: VehicleFilterService) {}

  ngOnInit(): void {
    this.pageReset();
  }

  private emitPageChange(): void {
    this.pageChange.emit({
      page: this.currentPage,
      pageSize: this.pageSize,
    });
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.emitPageChange();
  }

  onPageSizeChange(page: number): void {



    const maxPage = Math.ceil(this.totalItems / page) - 1;

    this.pageSize = page;
    this.currentPage = Math.max(1, Math.min(this.currentPage, maxPage));
    this.vehicleFilterService.pageReset$.subscribe(
      () => (this.currentPage = page)
    );
    this.emitPageChange();
  }

  pageReset(): void {
    this.vehicleFilterService.pageReset$.subscribe(
      () => (this.currentPage = 1)
    );
  }

  getPaginationRange(): string {
    const start =
      this.currentPage > 1 ? (this.currentPage - 1) * this.pageSize + 1 : 1;

    const end =
      this.currentPage * this.pageSize > this.totalItems
        ? this.totalItems
        : this.currentPage * this.pageSize;

    return `Exibindo ${start} ao ${end}`;
  }
}
