import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, bdAuth } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import { Observable } from 'rxjs';
import { IGetReportDTOInputParams, IUser } from '../models/reports.model';
import { IProposal } from '../models/reports.model';

@Injectable({
  providedIn: 'root',
})
export class ReportAdminService extends BaseApiService {
  proposals({ domain }: IGetReportDTOInputParams): Observable<IProposal[]> {
    return this.makeRequest(
      () =>
        this.http.get<IProposal[]>(`${apiUrl}/${apiVersion}/export`, {
          params: { domain },
        }),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }

  users(): Observable<IUser[]> {
    return this.makeRequest(
      () => this.http.get<IUser[]>(`${apiUrl}/${apiVersion}/users`),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }
}
