<section id="conheca-os-modelos" class="vehicles mt-5">
  <div
    class="row d-flex flex-column flex-md-row justify-content-between align-items-center">
    <div class="col-sm-12 col-md-6">
      <h2 class="mb-0">{{ titleLabel }}</h2>
    </div>

    <div class="col-sm-12 col-md-5 mt-3 mt-md-0 text-md-end">
      <app-button
        *ngIf="showButton"
        label="Ver todos"
        layout="bg-primary-color text-primary-color"
        (buttonClick)="openAllVehicles()"></app-button>

      <app-filters *ngIf="!showButton && showFilters"></app-filters>
    </div>
  </div>

  <app-card-skeleton
    [isLoading]="isLoading"
    [quantityRepeteLoading]="quantityRepeteLoading"></app-card-skeleton>

  <p-carousel
    *ngIf="!isLoading && (isMobile$ | async) && useSlide; else desktopView"
    class="vehicle-grid-container d-block mt-4"
    [value]="vehicles.items"
    [numVisible]="1.1"
    [numScroll]="1"
    [circular]="false"
    [showIndicators]="false"
    [showNavigators]="false">
    <ng-template let-vehicle pTemplate="item">
      <app-vehicle-card [vehicle]="vehicle"></app-vehicle-card>
    </ng-template>
  </p-carousel>

  <ng-template #desktopView >
    <ng-container *ngIf="!isLoading">
      <div class="row row-cols-2 row-cols-md-2 row-cols-lg-4 g-4 mt-2">
        <ng-container *ngFor="let vehicle of vehicles?.items; let i = index">
          <app-vehicle-card [vehicle]="vehicle"></app-vehicle-card>

          <!-- Foi solicitado para deixar comentado o código -->
          <!-- É só remover a linha 41 e descomentar o código abaixo-->
          <!-- <ng-container
            *ngIf="
              !getDifferentCardIndex(i, isDifferentCard);
              else differentCard
            ">
            <app-vehicle-card [vehicle]="vehicle"></app-vehicle-card>
          </ng-container>

          <ng-template #differentCard>
            <app-how-it-work-card></app-how-it-work-card>
          </ng-template> -->
        </ng-container>
      </div>
    </ng-container>
  </ng-template>

  <app-paragraph *ngIf="vehicles?.items?.length === 0">
    Nenhum veículo encontrado.
  </app-paragraph>
</section>
