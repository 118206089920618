import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { IProposal, IUser } from './models/reports.model';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { IGetReportDTOInputParams } from './models/reports.model';
import { ReportAdminService } from './services/reports.service';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-adm-report',
  standalone: true,
  templateUrl: 'reports.component.html',
  styleUrls: ['reports.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatIconModule,
  ],
})
export class ReportAdminComponent implements OnInit {
  @ViewChild('usersPaginator', { static: false, read: MatPaginator })
  usersPaginator!: MatPaginator;
  @ViewChild('proposalsPaginator', { static: false, read: MatPaginator })
  proposalsPaginator!: MatPaginator;

  proposalsDataSource = new MatTableDataSource<IProposal>();
  proposals = {
    columns: [
      'createdAt',
      'id',
      'cnpj',
      'company',
      'promoter',
      'amount',
      'terms',
      'actions',
    ] as (keyof IProposal)[],
    actions: new Set<IProposal>(),
  };

  usersDataSource = new MatTableDataSource<IUser>();
  users = {
    columns: [
      'createdAt',
      'domain',
      'name',
      'mail',
      'company',
      'cnpj',
      'role',
    ] as (keyof IUser)[],
  };

  constructor(
    public navigationService: NavigationService,
    private route: ActivatedRoute,
    private services: ReportAdminService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['domain']) {
        this.getUsers();
        this.getProposals({ domain: params['domain'] });
      }
    });
  }

  ngAfterViewInit() {
    this.proposalsPaginator.firstPage();
    this.usersPaginator.firstPage();
  }

  getProposals(params: IGetReportDTOInputParams) {
    this.services.proposals(params).subscribe({
      next: response => {
        this.proposalsDataSource.data = response.map(i => ({
          ...i,
          id: i.id.split('-').at(-1) || i.id,
          createdAt: new Date(i.createdAt).toLocaleDateString(),
          amount: i.proposal.reduce((acc, item) => acc + item.amount, 0),
          terms: [
            ...new Set(
              i.proposal.flatMap(item =>
                item.terms.map(t => t?.term?.toString().trim())
              )
            ),
          ].join(', '),
        }));

        this.proposalsDataSource.paginator = this.proposalsPaginator;
      },
      error: error => {
        console.error(error);
      },
    });
  }

  getUsers() {
    this.services.users().subscribe({
      next: response => {
        this.usersDataSource.data = response
          .filter(i =>
            ['PENDING_REGISTER_ENTITY', 'COMPLETED'].includes(String(i.status))
          )
          .map(i => ({
            createdAt: i.createdAt,
            domain: i.domain,
            name: i.name,
            mail: i.email,
            company: i.entity?.name,
            cnpj: i.entity?.nationalRegistry.number,
            role: i.role,
          })) as unknown as IUser[];

        this.usersDataSource.paginator = this.usersPaginator;
      },
      error: error => {
        console.error(error);
      },
    });
  }
}
