
<section class="report" about="users">
  <h3 >Usuários</h3>

  <ng-container  *ngIf="usersDataSource.data.length > 0; else notItem">

    <table mat-table [dataSource]="usersDataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data de Criação</th>
        <td mat-cell *matCellDef="let element">{{element.createdAt | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="domain">
        <th mat-header-cell *matHeaderCellDef>Domínio</th>
        <td mat-cell *matCellDef="let element">{{element.domain}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="mail">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{element.mail}}</td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef>Empresa</th>
        <td mat-cell *matCellDef="let element">{{element.company}}</td>
      </ng-container>

      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">{{element.cnpj}}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef>Cargo</th>
        <td mat-cell *matCellDef="let element">{{element.role}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="users.columns"></tr>
      <tr mat-row *matRowDef="let row; columns: users.columns;"></tr>
    </table>

    <!-- <mat-paginator #usersPaginator class="paginator" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"  showFirstLastButtons></mat-paginator> -->

  </ng-container>
  <ng-template #notItem>
    <div style="text-align: center;">Não há dados até o momento</div>
  </ng-template>
</section>


<section class="report" about="proposals">
  <h3>Propostas</h3>

  <ng-container  *ngIf="proposalsDataSource.data.length > 0; else notItem">

    <table mat-table [dataSource]="proposalsDataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data de Criação</th>
        <td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
      </ng-container>


      <ng-container matColumnDef="cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>
        <td mat-cell *matCellDef="let element">{{element.cnpj}}</td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef>Empresa</th>
        <td mat-cell *matCellDef="let element">{{element.clientName}}</td>
      </ng-container>

      <ng-container matColumnDef="promoter">
        <th mat-header-cell *matHeaderCellDef>Promotor(a)</th>
        <td mat-cell *matCellDef="let element">{{element.promoterName}}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Quantidade</th>
        <td mat-cell *matCellDef="let element">{{element.amount}}</td>
      </ng-container>

      <ng-container matColumnDef="terms">
        <th mat-header-cell *matHeaderCellDef>Prazos</th>
        <td mat-cell *matCellDef="let element">{{element.terms}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          <a [href]="element.downloadUrl" target="_blank" download>
            <mat-icon class="icon-custom" color="#000000">download</mat-icon>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="proposals.columns"></tr>
      <tr mat-row (click)="proposals.actions.add(row)" [class.demo-row-is-clicked]="proposals.actions.has(row)"
        *matRowDef="let row; columns: proposals.columns;"></tr>
    </table>


    <!-- <mat-paginator #proposalsPaginator class="paginator" [length]="this.proposalsDataSource.filteredData.length"  [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"  showFirstLastButtons></mat-paginator> -->

  </ng-container>
  <ng-template #notItem>
    <div style="text-align: center;">Não há dados até o momento</div>
  </ng-template>
</section>



