import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterService } from '@core/services/footer.service';
import { AuthBaseComponent } from '@modules/auth/components/auth-base/auth-base.component';
import {
  FlowName,
  FlowTitle,
} from '@modules/auth/services/auth-flow/enums/auth-flow.enum';
import { AccountSummaryBaseComponent } from '@modules/pages/account-summary/components/account-summary-base/account-summary-base.component';
import {
  AccountSummaryFlowName,
  AccountSummaryFlowTitle,
} from '@modules/pages/account-summary/components/account-summary-base/enums/account-summary.enum';
import { CartItemBaseComponent } from '@modules/pages/vehicles/components/proposal/cart-item-base/cart-item-base.component';
import { CartFlowName } from '@modules/pages/vehicles/components/proposal/cart-item-base/enums/cart-item.base.enum';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';
import { SidebarDrawerComponent } from '@shared/components/sidebar-drawer/sidebar-drawer.component';
import { LoadingComponent } from '@shared/components/skeleton/loading/loading.component';
import { ToastModule } from 'primeng/toast';
import { FooterComponent } from './shared/components/footer/footer.component';
import { ColorService } from '@core/services/color-service';
import { dynamicHost } from './app.api';
import { AboutUsService } from '@modules/pages/home/components/about-us/services/about-us.services';
import { AboutUs } from '@modules/pages/home/components/about-us/models/about-us.model';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    ToastModule,
    FooterComponent,
    SidebarDrawerComponent,
    AuthBaseComponent,
    CartItemBaseComponent,
    AccountSummaryBaseComponent,
    LoadingComponent,
  ],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
  flowName = FlowName;
  flowTitle: string = FlowTitle.createAccount;
  accountSummaryFlowName = AccountSummaryFlowName;
  cartFlowName = CartFlowName;
  currentContent: string | null = null;
  showFooter: boolean = false;
  isLoading: boolean = true;

  constructor(
    private aboutUsService: AboutUsService,
    private colorService: ColorService,
    private footerService: FooterService,
    private sidebarDrawerService: SidebarDrawerService
  ) {}

  ngOnInit(): void {
    this.subscribeToContentChanges();

    this.aboutUsService.aboutUs(dynamicHost).subscribe({
      next: async (aboutUs: AboutUs) => {
        this.colorService.applyColors(aboutUs);
        this.isLoading = false;
      },
    });
  }

  ngAfterViewInit(): void {
    this.watchFooterData();
  }

  subscribeToContentChanges(): void {
    this.sidebarDrawerService.content$.subscribe(content =>
      this.getCurrentContent(content)
    );
  }

  getCurrentContent(content: string | null): void {
    if (content === this.flowName.signup)
      this.flowTitle = FlowTitle.createAccount;
    else if (content === this.flowName.login) this.flowTitle = FlowTitle.login;
    else if (content === this.cartFlowName.cart) this.flowTitle = 'Proposta';
    else if (content === this.accountSummaryFlowName.users)
      this.flowTitle = AccountSummaryFlowTitle.myAccount;

    this.currentContent = content;
  }

  watchFooterData(): void {
    this.footerService
      .watchFooterData()
      .subscribe(data => (this.showFooter = data.showFooter));
  }

  updateFlowTitle(title: string): void {
    this.flowTitle = title;
  }
}
